var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ConstructionCertifierLicenseHeader',{attrs:{"title-km":"អាជ្ញាបណ្ណត្រួតពិនិត្យនិងបញ្ជាក់សំណង់","title-en":"CONSTRUCTION CERTIFIER LICENSE"}}),_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12",staticStyle:{"margin-top":"25px"}},[_c('table',{staticClass:"certificate-data-table table table-bordered"},[_c('tbody',[_vm._l((_vm.licenses),function(license,key){return [_c('tr',{key:key,class:{
                'bg-table': key % 2 !== 0,
                'bg-color-white': key % 2 === 0,
              }},[_c('td',{staticClass:"font-khmer"},[_c('p',{staticClass:"symbol"},[_vm._v(_vm._s(license.label_km))])]),_c('td',{staticClass:"font-khmer"},[_c('p',[_vm._v(_vm._s(license.value_km || '-'))])])]),_c('tr',{key:`${key}${(new Date()).getMilliseconds()}`,class:{
                'bg-table': key % 2 !== 0,
                'bg-color-white': key % 2 === 0,
              }},[_c('td',{staticClass:"font-khmer"},[_c('p',{staticClass:"symbol",domProps:{"innerHTML":_vm._s(license.label_en )}})]),_c('td',{staticClass:"font-khmer"},[_c('p',[_vm._v(_vm._s(license.value_en || '-'))])])])]})],2)])])])])]),_c('ConstructionCertifierLicenseFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }