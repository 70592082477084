<template>
  <div style="background-color: rgb(247, 247, 247); margin-top: 20px;">
    <div class="container-fluid" style="padding-top: 15px;">
      <p  class="text-center mb-0"> © ២០២០ រក្សាសិទ្ធិគ្រប់យ៉ាងដោយ <span class="font-weight-bold">អគ្គនាយកដ្ឋានសំណង់</span></p>
      <p class="text-center mb-0"> © 2020 Copyright by General Department of Construction </p></div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionCertifierLicenseFooter'
}
</script>

<style scoped>

</style>
