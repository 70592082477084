<template>
  <div>
    <ConstructionCertifierLicenseHeader
      title-km="អាជ្ញាបណ្ណត្រួតពិនិត្យនិងបញ្ជាក់សំណង់"
      title-en="CONSTRUCTION CERTIFIER LICENSE"
    />
    <div>
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-12" style="margin-top: 25px;">
            <table class="certificate-data-table table table-bordered">
              <tbody>
              <template v-for="(license, key) in licenses">
                <tr
                  :key="key"
                  :class="{
                  'bg-table': key % 2 !== 0,
                  'bg-color-white': key % 2 === 0,
                }">
                  <td class="font-khmer">
                    <p class="symbol">{{ license.label_km }}</p>
                  </td>
                  <td class="font-khmer">
                    <p>{{ license.value_km || '-' }}</p>
                  </td>
                </tr>
                <tr
                  :key="`${key}${(new Date()).getMilliseconds()}`"
                  :class="{
                  'bg-table': key % 2 !== 0,
                  'bg-color-white': key % 2 === 0,
                }">
                  <td class="font-khmer">
                    <p class="symbol" v-html="license.label_en "></p>
                  </td>
                  <td class="font-khmer">
                    <p>{{ license.value_en || '-' }}</p>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
            </div>
          </div>
      </div>
    </div>
    <ConstructionCertifierLicenseFooter/>
  </div>
</template>

<script>
import ConstructionCertifierLicenseHeader from '@/views/company/includes/ConstructionCertifierLicenseHeader'
import ConstructionCertifierLicenseFooter from '@/views/company/includes/ConstructionCertifierLicenseFooter'

export default {
  name: 'ConstructionCertifierLicense',
  metaInfo () {
    return {
      title: 'Construction Certifier License',
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: {
    ConstructionCertifierLicenseFooter,
    ConstructionCertifierLicenseHeader
  },
  data () {
    return {
      licenses: [
        { label_en: 'Company Name', label_km: 'នាមករណ៍ក្រុមហ៊ុន', key: 'company_name', value_en: '', value_km: '' },
        { label_en: 'Company Registration N<sup><u>o</u></sup>', label_km: 'បញ្ជីពាណិជ្ជកម្មលេខ', key: 'construction_certifier_mfc_number', value_en: '', value_km: '' },
        { label_en: 'Dated', label_km: 'កាលបរិច្ឆេទចុះបញ្ជីពាណិជ្ជកម្ម', key: 'construction_certifier_mfc_date', value_en: '', value_km: '' },
        { label_en: 'Head Office', label_km: 'ទីស្នាក់ការ', key: 'head_office', value_en: '', value_km: '' },
        { label_en: 'President/CEO', label_km: 'ប្រធានក្រុមហ៊ុន', key: 'president_ceo', value_en: '', value_km: '' },
        { label_en: 'Technical Director', label_km: 'នាយកបច្ចេកទេស', key: 'technical_director', value_en: '', value_km: '' },
        { label_en: 'Construction Certifier License N<sup><u>o</u></sup>', label_km: 'អាជ្ញាបណ្ណត្រួតពិនិត្យនិងបញ្ជាក់សំណង់ លេខ', key: 'construction_certifier_number', value_en: '', value_km: '' },
        { label_en: 'Class', label_km: 'ផ្នែក', key: 'class', value_en: '', value_km: '' },
        { label_en: 'Level', label_km: 'កម្រិត', key: 'level', value_en: '', value_km: '' },
        { label_en: 'First Issue Date', label_km: 'កាលបរិច្ឆេទផ្តល់អាជ្ញាបណ្ណដំបូង', key: 'first_issue_date', value_en: '', value_km: '' },
        { label_en: 'Valid until', label_km: 'សុពលភាពត្រឹមថ្ងៃទី', key: 'valid_until', value_en: '', value_km: '' }
      ]
    }
  },
  methods: {
    getConstructionCertifierLicense () {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/get-construction-certifier-license-data', {
        qr_code: this.$route.params.qr_code
      }).then(({ data }) => {
        if (data.data) {
          const license = data.data
          this.licenses.forEach((item) => {
            item.value_en = license[`${item.key}_en`]
            item.value_km = license[`${item.key}_km`]
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getConstructionCertifierLicense()
  }
}
</script>

<style scoped>
@import "./../../assets/css/customeTable.css";
</style>
