<template>
  <div>
    <div class="qr-code-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="qr-header">
              <div class="row">
                <div class="qr-header-logo col-sm-12 col-md-2">
                  <img
                    src="https://api-ccinspection.asoradev.com/img/logo/MLMUPC.png"
                    alt="Logo" class="logo">
                </div>
                <div class="qr-header-brand col-12 col-md-10">
                  <div class="text-center">
                    <h2 class="font-header">ក្រសួងរៀបចំដែនដី នគរូបនីយកម្ម និងសំណង់</h2>
                    <h4 class="font-en">Ministry of Land Management, Urban Planning and Construction</h4></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qr-code-tips-container">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="qr-tips-occupancy text-center">
              <p class="font-header" style="font-size: 18px;">{{ titleKm  }}</p>
              <p class="font-en" style="font-size: 18px;">{{ titleEn }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructionCertifierLicenseHeader',
  props: {
    titleKm: {
      type: String,
      default: 'ព័ត៌មានវិញ្ញាបនបត្រប្រើប្រាស់សំណង់'
    },
    titleEn: {
      type: String,
      default: 'Certificate of Occupancy'
    }
  }
}
</script>

<style scoped>

</style>
